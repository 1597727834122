import { graphql } from "gatsby";
import React from "react";
import NavigationBarSpacer from "../../components/NavigationBarSpacer";
import SEO from "../../components/Seo";
import Layout from "../../containers/Layout";
import { useTranslation } from "react-i18next";
import Section from "../../containers/Section";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
interface CookieListProps {
  data: any;
}

const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body?.appendChild(script);
    return () => {document.body.removeChild(script)}
  }, [url]);
};

const CookieList: React.FC<CookieListProps> = ({ data }) => {
  const { t } = useTranslation();
  useScript("https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js");
  const pageData = data;
  const { language } = useI18next();

  return (
    <Layout page="CookieList" locale={language}>
      <SEO
        title={
          t("Perpetua.io Cookie List")
        }
        robots="noindex, nofollow"
      />
       <NavigationBarSpacer />
      <Section className="mx-auto">
        <div className="mx-auto max-w-2xl mt-18">
          {/* CookiePro Cookies List start */}
          <div id="ot-sdk-cookie-policy"></div>
          {/* CookiePro Cookies List end */}
        </div>
      </Section>
    </Layout>
  );
};

export default CookieList;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;